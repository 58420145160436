<template>
    <recess-modal variant="variant2" :show-modal="isModalVisible" @close="$emit('close')">
        <template slot="modal-title">
            <h3 class="u-fw-semi-bold qa-assorted-products-list-popup-title">
                {{ PROVIDER.Portfolio.PortfolioOverview.Popup.AssortedProductsList.Title }}
            </h3>
        </template>

        <template slot="modal-body">     
            <recess-table-flex 
                class="qa-table-assorted-product-list col-12" 
                :table-options="setTableOptions"
                :pagination-options="setPaginationOptions"
                @getData="updateList"
            >       

                <recess-table-flex-head :set-fixed-header="true">
                    <recess-table-flex-row>
                        <recess-table-flex-head-item
                            v-for="header in headers"
                            :key="header.key"
                            :cell-size="header.cellSize"
                        >
                            <p class="m-0">{{ header.title }}</p>
                        </recess-table-flex-head-item>
                    </recess-table-flex-row>
                </recess-table-flex-head>


                <recess-table-flex-body >
                    <recess-table-flex-row v-for="(assortedProduct, assortedProductIndex) in assortedProducts" :key="assortedProductIndex">
                        <recess-table-flex-cell :data-label="headers[0].title" cell-size="large" :class="`qa-assorted-product-list-account-name-${assortedProductIndex}`">
                            <span class="d-flex u-text-semi-bold">{{ assortedProduct.accountName }} </span>
                        </recess-table-flex-cell>
                        <recess-table-flex-cell :data-label="headers[1].title" cell-size="large"  :class="`qa-assorted-product-list-assortment-name-${assortedProductIndex}`">
                            <span class="d-flex u-text-semi-bold">{{ assortedProduct.assortmentName }} </span>
                        </recess-table-flex-cell>
                        <recess-table-flex-cell :data-label="headers[2].title" cell-size="large" :class="`qa-assorted-product-list-btw-excl-with-discount-value-${assortedProductIndex}`">
                            <span class="d-flex u-text-semi-bold">{{ assortedProduct.amountWithoutVatWithDiscount | currency }} </span>
                        </recess-table-flex-cell>
                        <recess-table-flex-cell :data-label="headers[3].title" cell-size="large" :class="`qa-assorted-product-list-btw-with-discount-${assortedProductIndex}`">
                            <span class="d-flex u-text-semi-bold">{{ assortedProduct.vatWithDiscount | currency }} </span>
                        </recess-table-flex-cell>
                        <recess-table-flex-cell :data-label="headers[4].title" cell-size="large" :class="`qa-assorted-product-list-discount-excl-btw-${assortedProductIndex}`">
                            <span class="d-flex u-text-semi-bold">{{ assortedProduct.discountWithoutVat | currency }} </span>
                        </recess-table-flex-cell>
                    </recess-table-flex-row>
                </recess-table-flex-body>
               
            </recess-table-flex>
        </template>

        <template slot="modal-footer">
            <div class="d-flex justify-content-end mt-5">
                <recess-button
                    variant="tertiary"
                    :title="BUTTON_TEXT.close"
                    class="col-12 col-md-3 qa-assorted-products-list-popup-close-button"
                    @click.native.prevent="closeModal()"
                />                
            </div>
        </template>
    </recess-modal>
</template>

<script>
import { BUTTON_TEXT, API_CALL_URL_PATHS, PAGINATION } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { getItemsFromSearchIndex } from '../../../../shared/api/SharedClient' 

import tableMixin from '@/../../shared/mixins/tableMixin' 

export default {
    name: 'AssortedProductsListPopupComponent', 
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        },
        courseId: {
            type: String,
            default: null
        }
    },
    mixins: [tableMixin],
    data() {
        let paginationOptions = [
            { displayText: '5', value: 5 },
            { displayText: '10', value: 10 },
            { displayText: '25', value: 25 },
            { displayText: '50', value: 50 },
            { displayText: '100', value: 100 }, 
            { displayText: '250', value: 250 }
        ]

        return {
            BUTTON_TEXT,
            PROVIDER,
            PAGINATION,
            assortedProducts: [], 
            headers: [
                {
                    key: 'accountName',
                    title: PROVIDER.Portfolio.PortfolioOverview.Popup.AssortedProductsList.Headers.AccountName
                },
                {
                    key: 'assortmentName',
                    title: PROVIDER.Portfolio.PortfolioOverview.Popup.AssortedProductsList.Headers.AssortmentName
                },
                {
                    key: 'amountWithoutVatWithDiscount',
                    title: PROVIDER.Portfolio.PortfolioOverview.Popup.AssortedProductsList.Headers.AmountWithoutVatWithDiscount
                },
                {
                    key: 'vatWithDiscount',
                    title: PROVIDER.Portfolio.PortfolioOverview.Popup.AssortedProductsList.Headers.VatWithDiscount
                },
                {
                    key: 'discountWithoutVat',
                    title: PROVIDER.Portfolio.PortfolioOverview.Popup.AssortedProductsList.Headers.DiscountWithoutVat
                },
            ],
            filter: {
                search: '',
                top: 0,
                skip: 0,
                searchMode: 'all',
                orderBy: ["accountName asc"],
                filter: `productReference eq 'course:${this.courseId}'`
            },

            // TABLE AND PAGINATION OPTIONS - REQUIRED
            setTableOptions: {
                title: PROVIDER.Portfolio.PortfolioOverview.Popup.AssortedProductsList.TableTitle,
                setCellSizes: true 
            },
            setPaginationOptions: { 
                showTopPagination: true, 
                showBottomPagination: false, 
                currentPage: this.$route.query.page !== undefined ? Math.round(Number(this.$route.query.page)) : 1,
                totalCount: null,
                selectOptions: paginationOptions,
                defaultTotalItemsPerPage: paginationOptions[2].value,
                itemsPerPageFromUrlQuery: this.$route.query.itemsPerPage !== undefined ? Number(this.$route.query.itemsPerPage) : paginationOptions[2].value,
                showSelectOption: true, 
                selectOptionsLabel: PAGINATION.selectOptionsLabel,
                paginationShowLabel: PAGINATION.paginationShowLabel,
                paginationOfLabel: PAGINATION.paginationOfLabel 
            } 


            
        }
    },
    methods: {
        async getAssortedProducts() {
            try {
                const response = await getItemsFromSearchIndex(
                    process.env.VUE_APP_ACCOUNT_API_URL,
                    API_CALL_URL_PATHS.assortedProducts,
                    API_CALL_URL_PATHS.search,
                    this.filter
                )
                if (!response) return
                
                this.assortedProducts = [] // reset orders for filtering again
                response.results.map((result) => {
                    this.assortedProducts.push(result.document)
                })
                 
                this.setPaginationOptions.totalCount = response.count
            } catch (error) {
                console.error('Something went wrong while retrieving assorted products', error)
            }
        },
        updateList({ currentPage, itemsPerPage }) {
            this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
            this.setPaginationOptions.currentPage = currentPage
 
            this.filter.top = itemsPerPage
            this.filter.skip = currentPage * itemsPerPage - itemsPerPage

            this.getAssortedProducts()
        },
        closeModal() {
            this.$emit('close')
        }
    }
}
</script>
